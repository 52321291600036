<template>
  <div>
    <account-information :account-information="accountInformation" />
    <business-information :business-information="businessInformation" />
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      required: true,
      type: Object
    }
  },
  components: {
    AccountInformation: () => import('./AccountInformation'),
    BusinessInformation: () => import('./BusinessInformation')
  },
  computed: {
    accountInformation() {
      return this.profile?.account_information;
    },
    businessInformation() {
      return this.profile?.business_information;
    }
  }
};
</script>
